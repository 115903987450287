import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
            tabLink: window.location.pathname,
            removeHeader: false,
        };
    }

    tabLink(tabLink) {
        this.setState({
            tabLink: tabLink
        })
    }

    handleLogOut(){
        localStorage.removeItem("token");
        localStorage.clear()
        sessionStorage.clear()
        this.setState({
            removeHeader: true
        })
        window.location.reload()
    }

    render(){
        return (
            <div className="side-bar">
                <div className="side-bar-backdrop"></div>
                <div className="side-bar-container1">
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/search' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/search')} to="/search">Search</Link> 
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/watch' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/watch')} to="/watch">Watch</Link> 
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/cease-and-desist' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/cease-and-desist')}  to="/cease-and-desist">Cease And Desist</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/manager' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/manager')}  to="/manager">Manager</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/patent' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/patent')}  to="/patent">Patent</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/control-center' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/control-center')} to="/control-center">Control Center</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/hearing' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/hearing')} to="/hearing">Hearing</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/whats-new' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/whats-new')} to="/whats-new">Whats New</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/image-search' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/image-search')} to="/image-search">Image Search</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/registration' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/registration')} to="/registration">Registration</Link>
                    </div>
                    {/* <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/watch-automation' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/watch-automation')} to="/watch-automation">Watch Automation</Link>
                    </div> */}
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/manage-profile' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/manage-profile')} to="/manage-profile">Manage Profile</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className={` list-items ${this.state.tabLink === '/auto-add-trademark' ? 'list-items-active' : ''}`} onClick={()=> this.tabLink('/auto-add-trademark')} to="/auto-add-trademark">Auto Add Trademark</Link>
                    </div>
                    <div className="side-bar-list">
                        <Link className="list-items" to="/" onClick={() => this.handleLogOut()}>Log Out</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;