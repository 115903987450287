import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

//--------Sentry--------
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://7427601eb1cc444b883da8f6bd17806a@o115073.ingest.sentry.io/5311700',
    environment: 'irisProduction',
    release: 'iris@' + process.env.npm_package_version,
});

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
), document.getElementById('mike'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
