import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import ControlCenter from '../ControlCenter';
import Manager from '../Manager';
import Patent from '../Patent';
import Search from '../Search';
import SignIn from '../SignIn';
import Watch from '../Watch';
import CeaseDesist from '../CeaseDesist';
import HearingReport from '../Hearing';
import WhatsNew from '../WhatsNew';
import ImageSearch from '../ImageSearch';
import Registration from '../Registration';
// import WatchAutomation from '../watchAutomation';
import ManageProfile from '../ManageProfile';
import AutoAddTrademark from '../Auto_add_trademark';

class Main extends Component{
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return (
            <div className="Main">
                <Switch>
                    <Route exact path="/" component={SignIn} />
                    <Route path="/control-center" component={ControlCenter} />
                    <Route path="/manager" component={Manager} />
                    <Route path="/patent" component={Patent} />
                    <Route path="/search" component={Search} />
                    <Route path="/watch" component={Watch} />
                    <Route path="/cease-and-desist" component={CeaseDesist} />
                    <Route path="/hearing" component={HearingReport} />
                    <Route path="/whats-new" component={WhatsNew} />
                    <Route path="/image-search" component={ImageSearch} />
                    <Route path="/registration" component={Registration} />
                    {/* <Route path="/watch-automation" component={WatchAutomation} /> */}
                    <Route path="/manage-profile" component={ManageProfile} />
                    <Route path="/auto-add-trademark" component={AutoAddTrademark} />
                </Switch>
            </div>
        );
    }
}

export default Main;