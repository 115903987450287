import React, { Component } from 'react';
//--------LIBRARIES--------
import { connect } from 'react-redux';

//--------ACTIONS--------
import { createImageSearch } from '../actions/actions';

//--------CUSTOM FUNCTIONS--------
import { checkValue } from "../functions/functions";

class ImageSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSearch: {
                image: null,
            }
        };
    }

    componentDidMount() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
    }

    handleimageSearchChange(userVariable, value, event) {
        event.preventDefault()
        this.setState({
            imageSearch: {
                ...this.state.imageSearch,
                [userVariable]: value
            }
        })
    }

    handleSubmit() {
        this.props.createImageSearch(this.state.imageSearch)
    }

    render() {
        return (
            <div className="image-search">
                <div className="m-top-20">
                    <div className="image-search-box">
                        <div>
                            <p className="image-search-heading">Image Search Engine</p>
                        </div>
                        <div>
                            <p className='image-search-text'>{checkValue(this.props.imageSearchMessage) ? this.props.imageSearchMessage : ''}</p>
                        </div>
                        <ul className="image-search-list">
                            <li>
                                <div>
                                    <label>Upload Image:-</label>
                                </div>
                                <div>
                                    <input className="image-search-input" type="file" onChange={(event) => this.handleimageSearchChange('image', event.target.files, event)} placeholder="Upload Image" />
                                </div>
                            </li>
                            <li>
                                <button className="image-search-button" type="button" onClick={() => this.handleSubmit()}>Search</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <h3>Total results: <span>{checkValue(this.props.imageSearchCount) ? this.props.imageSearchCount : ''}</span></h3>
                </div>
                <br></br>
                <div>
                    <h3>Predicted Text: <span>{checkValue(this.props.imageSearchPredictedWord) ? this.props.imageSearchPredictedWord : ''}</span></h3>
                </div>

                <table className="Table">
                    <thead className="Table-Head">
                        <tr className="Table-Head-Row">
                            <th className="Table-Head-Cell">Image</th>
                            <th className="Table-Head-Cell">Applied for Score</th>
                            <th className="Table-Head-Cell">Image Text Score</th>
                            <th className="Table-Head-Cell">Application Number</th>
                            <th className="Table-Head-Cell">Applied For</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.imageSearchData.length > 0 && this.props.imageSearchData.map(eachData =>
                                <tr key={eachData.number} className="Table-Body-Row">
                                    <td className="Table-Body-Cell"><img className="image-search-result-img" src={eachData.url} alt={eachData.applied_for}></img></td>
                                    <td className="Table-Body-Cell">{eachData.applied_for_score}</td>
                                    <td className="Table-Body-Cell">{eachData.image_text_score}</td>
                                    <td className="Table-Body-Cell">{eachData.number}</td>
                                    <td className="Table-Body-Cell">{eachData.applied_for}</td>
                                </tr>
                            )
                        }
                        {
                            this.props.imageSearchData.length === 0 &&
                            <tr className="Table-Body-Row No-Record-Row">
                                <td colSpan="10" className="Table-Body-Cell">No Results found</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    componentWillUnmount() {
        // this.setState({
        //     intervalIndex: clearInterval(this.state.intervalIndex)
        // });
    }
}

const mapDispatchToProps = {
    createImageSearch
};

function mapStateToProps(state) {
    return {
        imageSearchData: state.imageSearch.imageSearchData,
        imageSearchCount: state.imageSearch.imageSearchCount,
        imageSearchPredictedWord: state.imageSearch.imageSearchPredictedWord
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageSearch);